@import 'tailwindcss/base';
@import 'tailwindcss/components';
/*Custom imports must go here*/
@import '@zealy/design-system/dist/style.css';
/*Remaining tailwind utilities*/
@import 'tailwindcss/utilities';

body,
html,
#__next {
  @apply bg-primary w-full;
  height: 100dvh;
  scroll-behavior: smooth;
}

main {
  scroll-behavior: smooth;
}

@layer base {
  .blur-child > .blurred {
    filter: blur(4px);
  }

  .reset-margin-x {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }

  .display-1 {
    @apply headings-display-d1;
  }
  .display-2 {
    @apply headings-display-d2;
  }

  .heading-1 {
    @apply headings-headline-h1;
  }

  .heading-2 {
    @apply headings-headline-h2;
  }

  .heading-3 {
    @apply headings-headline-h3;
  }

  .title-1 {
    @apply headings-title-t1;
  }

  .title-2 {
    @apply headings-title-t2;
  }

  .body-xl {
    @apply body-paragraph-xl;
  }

  .body-lg {
    @apply body-paragraph-lg;
  }

  .body-md {
    @apply body-paragraph-md;
  }

  .body-sm {
    @apply body-paragraph-sm;
  }

  .body-bold {
    font-weight: 600;
  }

  .component-xl {
    @apply body-component-xl;
  }

  .component-lg {
    @apply body-component-lg;
  }

  .component-md {
    @apply body-component-md;
  }

  .component-sm {
    @apply body-component-sm;
  }

  .label-sm {
    @apply input-label-sm;
  }

  .label-md {
    @apply input-label-md;
  }

  .label-lg {
    @apply input-label-lg;
  }

  .label-xl {
    @apply input-label-xl;
  }

  .section-heading {
    @apply interface-sectionHeading;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar::-webkit-scrollbar {
    display: initial;
  }

  .scrollbar {
    -ms-overflow-style: initial; /* IE and Edge */
    scrollbar-width: initial; /* Firefox */
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 0px #00000000 inset;
  }

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading='lazy'] {
      clip-path: inset(0.6px);
    }
  }

  [cmdk-group-heading] {
    @apply px-select-md h-select-sm flex items-center;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


[data-tippy-root] {
  pointer-events: auto !important;
}
